import React from "react";
// import Header from "../components/Header";

function Blog() {
  return (
    <>
      {/* <Header /> */}
      <h1>Blog page</h1>
      
    </>
  );
}

export default Blog;
